import React from 'react';
import { Link } from 'gatsby';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from 'utils/linkResolver';
import { TAG_PORTAL_CHILD_PAGE } from '../../utils/constants';

const Card = styled(Link)`
  background-color: ${prop('theme.colors.white')};
  border: 1px solid #dbdbdb;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  max-width: 856px;
  padding: 24px;

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    max-width: initial;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: column;
    align-items: flex-end;
  }

  &:hover {
    text-decoration: none;
  }

  p {
    color: ${prop('theme.colors.black')};
    font-size: 0.825rem;
    font-weight: ${prop('theme.fontWeights.light')};
    line-height: 1rem;
  }
`;

const CardWrapper = styled.div`
  display: flex;
`;

const CardIcon = styled.div`
  padding-right: 22px;

  img {
    margin-top: -6px;
  }
`;

const CardDetails = styled.div`
  max-width: 642px;
  padding-right: 22px;

  &&& {
    p {
      margin: 0;
    }
  }

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    max-width: 856px;
  }
`;

const CardTitle = styled.h6`
  color: ${prop('theme.colors.black')};
  font-size: 1rem;
  font-weight: ${prop('theme.fontWeights.medium')};
  letter-spacing: 0.06em;
  line-height: 1rem;
  text-transform: uppercase;
`;

const propTypes = {
  data: PropTypes.shape({
    items: PropTypes.array,
    primary: PropTypes.object,
    slice_type: PropTypes.string
  }),
  pageUid: PropTypes.string,
  template: PropTypes.string
};

const defaultProps = {
  data: {},
  pageUid: '',
  template: ''
};

function ContentCardsBlock({ data, pageUid, template }) {
  const cards = get(data, 'items');
  const cardsCtaIcon = get(data, 'primary.cards_cta_icon');

  if (!cards) {
    return;
  }

  return (
    <>
      {cards.map((card, index) => {
        const { card_body, card_link, card_title, card_title_icon } = card;
        const cardLinkTag = get(card_link, 'tags.[0]');

        return (
          <Card
            key={index}
            to={linkResolver({
              type: card_link.type,
              uid: card_link.uid,
              parentUid: cardLinkTag === TAG_PORTAL_CHILD_PAGE ? pageUid : ''
            })}
          >
            <CardWrapper>
              {card_title_icon?.url && (
                <CardIcon>
                  <img
                    alt={card_title_icon.alt ?? 'uploaded iamge'}
                    src={card_title_icon.url}
                  />
                </CardIcon>
              )}
              <CardDetails>
                {card_title?.richText && (
                  <CardTitle>{card_title.text}</CardTitle>
                )}
                {card_body?.richText && (
                  <PrismicRichText
                    field={card_body.richText}
                    linkResolver={linkResolver}
                  />
                )}
              </CardDetails>
            </CardWrapper>
            {cardsCtaIcon?.url && (
              <img
                alt={cardsCtaIcon.alt ?? 'uploaded image'}
                src={cardsCtaIcon.url}
                height={
                  cardsCtaIcon.dimensions && cardsCtaIcon.dimensions.height
                }
                width={cardsCtaIcon.dimensions && cardsCtaIcon.dimensions.width}
              />
            )}
          </Card>
        );
      })}
    </>
  );
}

ContentCardsBlock.propTypes = propTypes;
ContentCardsBlock.defaultProps = defaultProps;

export default ContentCardsBlock;
